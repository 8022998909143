const nos_metiers = [{
        title: "Habitat & Résidentiel",
        img: "habitat",
        format: "jpg"
    },
    {
        title: "Immobilier d'entreprise",
        img: "immobilier",
        format: "jpg"
    },
    {
        title: "Équipement public",
        img: "equipement",
        format: "jpg"
    }
]

export default nos_metiers;